<!--
 * @Description: 
 * @Author: Chp
 * @Date: 2021-03-23 15:58:38
 * @LastEditTime: 2021-05-26 22:17:49
 * @LastEditors: Chp
 * @Reference: 
-->

<template>
  <div class="th-intro">
    <SonTitle title="同汇简介"></SonTitle>
    <img :src="intro.logo" alt="" />
    <div class="des" v-html="intro.introduce"></div>
  </div>
</template>

<script>
export default {
  computed: {
    intro () {
      return this.$store.state.IndexData.jianjie || {} // 取出简介
    }
  },
  data () {
    return {
    }
  },
  created () {
  },
  methods: {
  },
}
</script>

<style scoped lang='scss'>
.th-intro {
  img {
    max-width: 100%;
    margin-bottom: 45px;
  }
}
</style>